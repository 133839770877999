import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    EventEmitter,
    Output,
    ElementRef,
    ChangeDetectionStrategy,
    OnDestroy,
    SkipSelf,
    computed,
    ViewEncapsulation,
} from "@angular/core";
import { EventOccUtil } from "../s25.event.occurrence.util";
import { S25ModalComponent } from "../../../../s25-modal/s25.modal.component";
import { S25ReservationUtil } from "../../../utils/s25.reservation.util";
import { S25Util } from "../../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25EventOccurrencesService } from "../../s25.event.occurrences.service";
import { ModalService } from "../../../../modal/modal.service";
import { S25LoadingApi } from "../../../../s25-loading/loading.api";
import { Item } from "../../../../../pojo/Item";
import { EventDataMicroI } from "../../../EventMicroI";
import { EventMicroService } from "../../../../../services/event.micro.service";
import { S25ObjectSearchComponent } from "../../../../s25-object-search/s25.object.search.component";
import { S25Reservation } from "../../../ReservationI";
import { S25EventShareDataService } from "../../s25.event.shared.data.service";
import { Subscription } from "rxjs";
import { NotificationService } from "../../../../../services/notification.service";
import { TelemetryService } from "../../../../../services/telemetry.service";
import { Event } from "../../../../../pojo/Event";
import Rsrv = Event.Reservation.States;
import { S25ItemI } from "../../../../../pojo/S25ItemI";

@TypeManagerDecorator("occurrence-action")
@Component({
    selector: "occurrence-action",
    template: `
        @if (init) {
            @if (eventState === 98 || eventState === 99) {
                <div>None</div>
            }

            @if (eventState !== 98 && eventState !== 99) {
                <div class="occ-action-wrapper">
                    <div>
                        <select
                            class="cn-form__control"
                            aria-label="Occurrence State"
                            (change)="onChangeActive($event, occurrence()?.state)"
                        >
                            @for (state of rsrvStates; track state.itemId) {
                                <option [value]="state.itemId" [selected]="occurrence()?.state === state.itemId">
                                    {{ state.itemName }}
                                </option>
                            }
                        </select>
                    </div>
                    <div class="buttons">
                        <button
                            class="aw-button aw-button--outline"
                            (click)="onActionCopy()"
                            [disabled]="occurrence()?.state === 99 || !hasPerm"
                        >
                            Copy
                        </button>
                        <button
                            *ngIf="!singleRsrv"
                            class="aw-button aw-button--danger--outline"
                            (click)="onActionDelete()"
                        >
                            Delete
                        </button>
                        <button
                            class="aw-button aw-button--outline"
                            (click)="onObjectSearch(4)"
                            [disabled]="occurrence()?.state === 99"
                        >
                            Add Locations
                        </button>
                        <button
                            class="aw-button aw-button--outline"
                            (click)="onObjectSearch(6)"
                            [disabled]="occurrence()?.state === 99"
                        >
                            Add Resources
                        </button>
                    </div>

                    <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
                </div>
                <s25-ng-modal
                    #objectSearchModal
                    [title]="itemTypeId === 4 ? 'Locations Search' : 'Resources Search'"
                    [size]="'xl'"
                >
                    <ng-template #s25ModalBody>
                        <s25-ng-object-search
                            #objectSearchComponent
                            [itemTypeId]="itemTypeId"
                            [occ]="occurrence()"
                            [profileId]="profileId"
                            [eventId]="eventId"
                            [selectedItems]="accuUpdateItems"
                            (refreshF)="onRefresh($event)"
                        >
                        </s25-ng-object-search>
                    </ng-template>
                </s25-ng-modal>

                <s25-ng-modal #copyOccModal [title]="'Copy Occurrence'" [size]="'xl'">
                    <ng-template #s25ModalBody>
                        <occurrence-additional-details
                            [occ]="copyOcc"
                            [isCopy]="true"
                            [canEdit]="canEdit"
                            [eventId]="eventId"
                            [profileId]="profileId"
                            [copyInitOcc]="initOcc"
                            (onCancel)="cancel()"
                            (onSave)="copyOccModal.close(); refreshF.emit()"
                        >
                        </occurrence-additional-details>
                    </ng-template>
                </s25-ng-modal>
            }
        }
    `,
    styles: [
        `
            .occ-action-wrapper {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 235px;

                select {
                    width: 96%;
                }

                .buttons {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;

                    button {
                        width: 110px;
                    }
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceActionComponent implements OnDestroy, OnInit {
    @Input() occ: S25Reservation;
    @Input() eventState: number;
    @Input() eventId?: number;
    @Input() canEdit?: number;
    @Input() profileId?: number;
    @Input() singleRsrv?: boolean; // if single Rsrv no delete button
    @Input() selectedItems?: number[] = [];
    @Input() hasPerm?: boolean;
    @ViewChild("copyOccModal") copyOccModal: S25ModalComponent;
    @ViewChild("objectSearchModal") objectSearchModal: S25ModalComponent;
    @ViewChild("closeButton") closeButton: any;
    @ViewChild("objectSearchComponent") objSearchComp: S25ObjectSearchComponent;

    @Output() refreshF = new EventEmitter<void>();

    initOcc: S25Reservation;
    copyOcc: S25Reservation;
    itemTypeId: Item.Ids.Location | Item.Ids.Resource;
    accuUpdateItems: number[] = [];

    init: boolean = false;
    private selectedItemsSubscription: Subscription;

    // event: S25Event;
    event = this.occurrencesService.S25Event;
    occurrence = computed(() => {
        return this.occurrencesService.getOccurrences().find((rsrv) => rsrv.itemId === this.occ.itemId);
    });

    rsrvStates: S25ItemI[] = [];

    constructor(
        private occurrencesService: S25EventOccurrencesService,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        @SkipSelf() private shareDataService: S25EventShareDataService,
    ) {}

    async ngOnInit() {
        this.initOcc = S25Util.deepCopy(this.occurrence());
        this.selectedItemsSubscription = this.shareDataService.getSelectedItems().subscribe((items) => {
            this.selectedItems = items;
        });

        this.rsrvStates = await S25ReservationUtil.getRsrvState(this.occ.state);

        this.hasPerm = S25ReservationUtil.checkObjHasPerm(this.occurrencesService.getOccurrences());
        this.init = true;
        this.cd.detectChanges();
    }

    async onActionCopy() {
        this.copyOcc = await EventOccUtil.copyOcc(this.occurrence());
        this.copyOccModal.open();
    }

    async onObjectSearch(id: number) {
        this.accuUpdateItems = Object.values(S25Util.clone(this.selectedItems));
        if (!this.accuUpdateItems.includes(this.occurrence().itemId))
            this.accuUpdateItems.push(this.occurrence().itemId);
        this.itemTypeId = id;
        this.objectSearchModal.open();
    }

    async onActionDelete() {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this occurrence?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no
        this.save(true);
    }

    async onChangeActive(e: any, oldVal: number) {
        let newVal = parseInt(e.target.value);
        let payload: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: this.eventId,
                    profiles: [
                        {
                            profileId: this.profileId,
                            reservations: [
                                {
                                    rsrvId: this.occ.itemId, //rsrvId
                                    state: newVal,
                                },
                            ],
                        },
                    ],
                },
            ],
        }; //end payload

        if (newVal === Rsrv.Cancelled) {
            let dialogData = ModalService.dialogType(
                "Yes No",
                {
                    message: "Are you sure you want to cancel this occurrence?",
                    title: "Confirm Cancelled",
                },
                "No",
            );

            await ModalService.modal("dialog", dialogData);
            if (dialogData.answer !== 1) {
                this.init = false;
                this.cd.detectChanges();
                this.init = true;
                this.cd.detectChanges();
                return; // User answered no
            }
        }

        S25ReservationUtil.setState(this.occurrence(), newVal);
        this.setLoading(true);
        TelemetryService.sendWithSub("EventDetails", "EventDetailsOcc", "EditMode");
        const [ok, error] = await S25Util.Maybe(EventMicroService.putEventReservation(this.eventId, payload));
        this.setLoading(false);
        if (ok) {
            if (ok?.content?.errors) {
                for (let error of ok?.content?.errors) {
                    NotificationService.post(error.message);
                }
            } else {
                this.refreshF.emit();
            }
        }
        if (error) return S25Util.showError(error, "There was an error while attempting to update this reservation.");
    }

    cancel() {
        this.occ = this.initOcc;
        this.refreshF.emit();
        this.copyOccModal.close();
    }

    async save(isDeleted: boolean) {
        const event = this.occurrencesService.S25Event;
        const normalizeSaveData = await EventOccUtil.normalizeEventWSData(event, this.occurrence(), false, isDeleted);
        let data = normalizeSaveData[0].data;
        if (isDeleted) {
            delete data.items[0].profiles[0].reservations;
            data.items[0].profiles[0].remove = { reservations: [{ rsrvId: this.occurrence().itemId }] };
        }

        this.setLoading(true);
        TelemetryService.sendWithSub("EventDetails", "EventDetailsOcc", isDeleted ? "DeleteOccurrence" : "EditMode");
        const [ok, error] = await S25Util.Maybe(EventMicroService.microPutEventDetail(data, this.eventId));
        this.setLoading(false);
        if (ok) {
            this.refreshF.emit();
        }
        if (error) return S25Util.showError(error, "There was an error while attempting to update this reservation.");
    }

    onRefresh(e: boolean) {
        //fetch get Event
        if (e) {
            this.refreshF.emit();
        }
        this.objectSearchModal.close();
    }

    setLoading(yes: boolean) {
        if (yes) {
            S25LoadingApi.init(this.elementRef.nativeElement);
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.unsubscribe();
        }
    }
}
