import { S25Util } from "../../../../util/s25-util";
import { EventService, Etags } from "../../../../services/event.service";
import { S25ProfileUtil } from "../../utils/s25.profile.util";
import { ReservationService } from "../../../../services/reservation.service";
import { WsDataI, EventMircotI, EventDataMicroI, WSReservations } from "../../EventMicroI";
import { AvailLocationItems, AvailResourceItems } from "../../../s25-object-search/s25.object.search.util";
import {
    S25Reservation,
    S25ObjectReservation,
    S25RsReservation,
    S25RmReservation,
    ObjectType,
} from "../../ReservationI";
import { S25Event } from "../../EventMicroI";
import { S25Profile, AdditionalTime, Ids, RecordType } from "../../ProfileI";

declare global {
    interface Window {
        angBridge: any;
    }
}
export class EventOccUtil {
    public static async copyOcc(occ: S25Reservation) {
        let newRsrvId: any = await ReservationService.generateReservationIds(1);
        let copyOcc: S25Reservation[] = [];
        copyOcc.push({
            itemId: newRsrvId.idSet[0].rsrvId,
            locations: occ.locations,
            resources: occ.resources,
            comments: occ.comments,
            profileId: occ.profileId,
            profileName: occ.profileName,
            state: 1,
            eventStart: occ.eventStart,
            eventEnd: occ.eventEnd,
        } as S25Reservation);

        if (occ.postEventEnd) copyOcc[0].postEventEnd = occ.postEventEnd;
        if (occ.preEventStart) copyOcc[0].preEventStart = occ.preEventStart;
        if (occ.setupStart) copyOcc[0].setupStart = occ.setupStart;
        if (occ.takeDownEnd) copyOcc[0].takeDownEnd = occ.takeDownEnd;
        return copyOcc[0];
    }

    //eventEnd: S25Util.date.parse(S25Util.date.copyTime(new Date(), occ.eventEnd)),
    //     public static changeActive(e: any, occ: S25Reservation) {
    //         console.log({ e: e });
    //         return occ;
    //     }

    //     public static delOcc(occ: S25Reservation) {
    //         return occ;
    //     }

    //     public static saveOcc(occ: S25Reservation) {
    //         return occ;
    //     }

    //delete and copy occurrance apply  to this function
    public static normalizeEventWSData(
        event: EventMircotI,
        occ: S25Reservation,
        isCopy?: boolean,
        isDelete?: boolean,
        copyInitOcc?: S25Reservation,
    ) {
        let locations = S25ProfileUtil.normalizeObjectsData(occ.locations, "location");
        let resources = S25ProfileUtil.normalizeObjectsData(occ.resources, "resource");

        let findProfile = event.profile.find((p: S25Profile) => {
            return p?.itemId === occ.profileId;
        });

        //if not copy and delete, then update datetime changed
        if (!isCopy && !isDelete) {
            findProfile.reservations = findProfile.reservations.map((rsrv: S25Reservation) => {
                if (rsrv.itemId === occ.itemId) {
                    return { ...rsrv, ...occ };
                }
                return rsrv;
            });
        }

        //fixed copy occ duplicate
        if (isCopy) {
            findProfile.reservations = findProfile.reservations.map((rsrv: S25Reservation) => {
                if (rsrv.itemId === copyInitOcc.itemId) {
                    return { ...rsrv, ...copyInitOcc };
                }
                return rsrv;
            });
        }
        // converted to freeform, so user can added copy a date  since we have the occ have rsrvStartDt,
        findProfile.occurrenceDefn.recTypeId = RecordType.FreeForm;

        let reservations = S25ProfileUtil.normalizeRsrvWSData(findProfile.reservations);

        if (isDelete) {
            reservations = reservations.filter((item) => {
                return item.rsrvId !== occ.itemId;
            });
        }

        if (isCopy) {
            delete findProfile.occurrenceDefn?.profileCode;
            delete findProfile.occurrenceDefn?.adHocDates;
            let copyRsrv = {
                rsrvId: occ.itemId,
                comments: occ.comments,
                state: occ.state,
                occurrence: S25Util.date.toS25ISODateStr(occ.eventStart),
                rsrvStartDt:
                    S25Util.date.toS25ISODateTimeStr(occ.setupStart) ||
                    S25Util.date.toS25ISODateTimeStr(occ.preEventStart) ||
                    S25Util.date.toS25ISODateTimeStr(occ.eventStart),
                evStartDt: S25Util.date.toS25ISODateTimeStr(occ.eventStart),
                evEndDt: S25Util.date.toS25ISODateTimeStr(occ.eventEnd),
                rsrvEndDt:
                    S25Util.date.toS25ISODateTimeStr(occ.takeDownEnd) ||
                    S25Util.date.toS25ISODateTimeStr(occ.postEventEnd) ||
                    S25Util.date.toS25ISODateTimeStr(occ.eventEnd),
                spaces: locations,
                resources: resources,
            } as WSReservations;

            reservations.push(copyRsrv);

            // leave the codes here in case we  decided  not to converted to freeFrom, might need to adjust get right prfoldeCode and ad hoc dates
            //  if(findProfile?.occurrenceDefn?.recTypeId === RecordType.SingleDateTime ){
            //      findProfile.occurrenceDefn.recTypeId = RecordType.AdHoc;
            //      findProfile.occurrenceDefn.adHocDates = [ S25Util.date.toS25ISODateStr(occ.eventStart)];
            //  }else if(findProfile?.occurrenceDefn?.recTypeId === RecordType.AdHoc){
            //      findProfile.occurrenceDefn.adHocDates.push(S25Util.date.toS25ISODateStr(occ.eventStart));
            //  }else if(findProfile?.occurrenceDefn?.recTypeId === RecordType.RecurrenceGrammar){
            //      // converted to freeform, so user can added copy a date  since we have the occ have rsrvStartDt,
            //      findProfile.occurrenceDefn.recTypeId = RecordType.FreeForm;
            //      delete findProfile.occurrenceDefn.profileCode;
            //  }
        }

        let etags: Etags = { id: event.itemId, etag: event.etag };
        let data: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: event.itemId,
                    name: event.itemName,
                    isActive: event.isActive,
                    dates: {
                        startDate:
                            S25Util.date.toS25ISODateTimeStr(occ.setupStart) ||
                            S25Util.date.toS25ISODateTimeStr(occ.preEventStart) ||
                            S25Util.date.toS25ISODateTimeStr(occ.eventStart),
                        endDate:
                            S25Util.date.toS25ISODateTimeStr(occ.takeDownEnd) ||
                            S25Util.date.toS25ISODateTimeStr(occ.postEventEnd) ||
                            S25Util.date.toS25ISODateTimeStr(occ.eventEnd),
                    },
                    profiles: [
                        {
                            profileId: occ.profileId,
                            name: occ.profileName,
                            occurrenceDefn: findProfile?.occurrenceDefn,
                            reservations: reservations,
                        },
                    ], // profiles
                },
            ], // items
        };
        return [{ eTags: etags, data: data }];
    }

    // All space and resource reservations input with no label (draft,  request  & reserved)
    //// add Object apply to this function
    public static normalizeAddRsrvWSData(
        newItem: AvailLocationItems | AvailResourceItems | null,
        addedItems: any[],
        type: ObjectType,
        requestQty: number,
    ) {
        let results = [];
        if (type === "location") {
            let share = newItem.always_shared === "T";
            if (newItem?.checkedObj?.conflicts) {
                share = newItem?.checkedObj?.conflicts.some((item: AvailLocationItems) => item.conflictShare === true);
            }

            if (newItem !== null) {
                results.push({
                    spaceId: newItem.space_id,
                    share: share,
                });
            }
        } else {
            if (newItem !== null) {
                results.push({
                    resourceId: newItem.resource_id,
                    quantity: requestQty,
                } as S25RsReservation);
            }
        }
        return results;
    }

    // All space and resource reservations input with no label (draft,  request  & reserved)
    // delete Object apply to this function
    public static normalizeDeleteRsrvWSData(
        removeItem: S25RmReservation | S25RsReservation | null | any,
        addItem: AvailLocationItems | AvailResourceItems | null,
        nodeName: string,
        occ: S25Reservation,
        type: ObjectType,
    ) {
        let results: any;
        let locations = S25ProfileUtil.normalizeObjectsData(occ.locations, "location");
        let resources = S25ProfileUtil.normalizeObjectsData(occ.resources, "resource");

        if (type === "location") {
            let dataset = {
                layoutId: removeItem?.layout?.itemId,
                instructions: removeItem.instructions,
                spaceId: removeItem.itemId,
            };
            results = { spaces: locations, remove: { spaces: [{ [nodeName]: [dataset] }] } };
        } else {
            let dataset = {
                instructions: removeItem.instructions,
                resourceId: removeItem.itemId,
            };
            results = { resources: resources, remove: { resources: [{ [nodeName]: [dataset] }] } };
        }
        return results;
    }
}
