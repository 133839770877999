import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25Util } from "../../../../../util/s25-util";
import { S25Reservation } from "../../../ReservationI";
import { Event } from "../../../../../pojo/Event";
import Rsrv = Event.Reservation.States;

@TypeManagerDecorator("occurrence-set-for-all")
@Component({
    selector: "occurrence-set-for-all",
    template: `@if (init) {
        <div>
            <s25-simple-collapse [headerText]="'Set For All'" [defaultCollapsed]="true">
                <div class="collapseContainer">
                    <p class="c-margin-left--half" tabindex="0">
                        By selecting occurrences from the below list, you will be able to apply changes to assignments,
                        instructions, shared and comments from one occurrence to all selected occurrences:
                    </p>
                    <div class="checkbox-container">
                        <div>
                            <s25-ng-checkbox
                                [modelValue]="false"
                                [ariaLabel]="'Apply All'"
                                (modelValueChange)="onSelectAll($event)"
                            ></s25-ng-checkbox>
                            Apply All
                        </div>
                        @for (occ of occurrencesList; track occ; let i = $index) {
                            @if (occ.state !== 99) {
                                <div>
                                    <s25-ng-shift-selectable-checkbox
                                        [id]="'item-' + i"
                                        [index]="i"
                                        [group]="'occ'"
                                        [modelValue]="selectAll"
                                        [ariaLabel]="occ.eventStart | dateFormat: dateFormat"
                                        (modelValueChange)="onCheckBoxChange(occ, $event)"
                                    ></s25-ng-shift-selectable-checkbox>
                                    {{ occ.eventStart | dateFormat: dateFormat }}
                                </div>
                            }
                        }
                    </div>
                </div>
            </s25-simple-collapse>
        </div>
    }`,
    styles: [
        `
            .collapseContainer {
                padding: 1rem;

                .checkbox-container {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    gap: 5px;
                    width: 95%;
                    margin: 1rem auto;
                    max-height: 30vh;
                }
            }

            ::ng-deep .simple-collapse--wrapper.c-margin-top--single {
                margin-top: unset !important;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceSetForAllComponent {
    @Input() occurrencesList?: S25Reservation[];
    @Input() dateFormat: string;
    @Input() timeFormat?: string;
    @Output() selectedRsrv = new EventEmitter<number[]>();
    init: boolean;
    selectedItems: number[] = [];
    selectAll: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }

    onSelectAll(event: boolean) {
        this.selectAll = event;
        if (event) {
            this.selectedItems =
                this.occurrencesList
                    ?.filter((occ: S25Reservation) => occ.state !== Rsrv.Cancelled)
                    .map((occ: S25Reservation) => occ.itemId) || [];
        } else {
            this.selectedItems = [];
        }
        this.selectedRsrv.emit(this.selectedItems);
        this.cd.detectChanges();
    }

    onCheckBoxChange(occ: S25Reservation, event: boolean) {
        if (event && !this.selectedItems.includes(occ.itemId)) {
            this.selectedItems.push(occ.itemId);
        } else {
            const index = this.selectedItems.indexOf(occ.itemId);
            if (index !== -1) this.selectedItems.splice(index, 1);
        }
        this.selectedRsrv.emit(this.selectedItems);
        this.cd.detectChanges();
    }
}
