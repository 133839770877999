import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { Table } from "../../../../s25-table/Table";
import { Bind } from "../../../../../decorators/bind.decorator";

import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25Reservation } from "../../../ReservationI";
@TypeManagerDecorator("occurrence-segment")
@Component({
    selector: "occurrence-segment",
    template: ` @if (init) {
        <div class="occ-segment-wrapper">
            @if (eventState && (eventState === 98 || eventState === 99)) {
                <div>{{ segmentName }}</div>
            }
            @if ((eventState && eventState !== 98 && eventState !== 99) || (occ.state !== 99 && occ.state !== 2)) {
                <button
                    (click)="showDetails()"
                    class="c-textButton toggle-details-button"
                    [class.expanded]="toggleShowDetails"
                >
                    <s25-ng-icon [type]="'caretRight'"></s25-ng-icon>
                    <div>{{ segmentName }}</div>
                </button>
            }
        </div>
    }`,
    styles: [
        `
            ::ng-deep .occ-segment-wrapper {
                min-width: 149px;
            }

            ::ng-deep .toggle-details-button {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            ::ng-deep .toggle-details-button svg {
                transition: transform 0.3s;
            }

            ::ng-deep .expanded svg {
                transform: rotate(90deg);
            }

            ::ng-deep .c-svgIcon:hover {
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class OccurrenceSegmentComponent {
    @Input() segmentName: string;
    @Input() occ: S25Reservation;
    @Input() eventState: number;

    @Output() toggleDetailsRow: EventEmitter<Table.NewRowModel> = new EventEmitter<Table.NewRowModel>();

    toggleShowDetails: boolean = false;
    init: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        this.init = true;
    }

    @Bind
    showDetails() {
        let table, row, allrows;
        if (window.innerWidth < 540) {
            // access HTML table elements when table is pivoted
            table = this.elementRef.nativeElement.parentElement.parentElement.parentElement.parentElement;
            row = this.elementRef.nativeElement.parentElement.parentElement;
            allrows = table.children[1].children;
        } else {
            table = this.elementRef.nativeElement.offsetParent.offsetParent;
            row = this.elementRef.nativeElement.offsetParent.parentElement;
            allrows = table.children[2].children;
        }

        const tableData: Table.NewRowModel = {
            table: table,
            row: row,
            rowIndex: Array.from(allrows).indexOf(row) - (window.innerWidth < 540 ? 1 : 0),
            action: this.toggleShowDetails ? "delete" : "create",
            data: { occ: this.occ, isCopy: false },
        };
        this.toggleShowDetails = !this.toggleShowDetails;
        this.toggleDetailsRow.emit(tableData);
        this.cd.detectChanges();
    }
}
